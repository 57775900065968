html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

:root {
  --color-red: #ec2427;
  --color-yellow: #ffca04;
  --color-green: #46b548;
}

@font-face {
  font-family: FuturaCondXBldO;
  src: url(../../assets/fonts/FuturaCondXBldO.otf) format('opentype');
}

.wrapper {
  background-color: black;
  color: white;
  padding: 4.1vw 6.4vw 4.1vw 6.7vw;
  width: 100vw;
  height: 100vh;
}

.safety-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.traffic-light-wrapper {
  width: 12.5vw;
  height: 100%;
}

.message-wrapper-2 {
  width: 70.3vw;
  height: 100%;
  position: relative;
}

.message-wrapper-2 .message-card-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Traffic light */

.traffic-light {
  position: relative;
  width: 100%;
  height: 100%;
}

.traffic-light[data-state='red'] .red {
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  animation-fill-mode: both;
  animation-delay: 1s;
  animation-duration: 1.5s;
  transition: opacity 1s cubic-bezier(0.5, 0, 1, 1) 0.5s;
}

.traffic-light[data-state='yellow'] .yellow {
  animation-name: blinker;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  animation-fill-mode: both;
  animation-delay: 1s;
  animation-duration: 4.5s;
  transition: opacity 1s cubic-bezier(0.5, 0, 1, 1) 0.5s;
}

.traffic-light[data-state='green'] .green {
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.5, 0, 1, 1) 0.5s;
}

.light {
  width: 12.5vw;
  height: 12.5vw;
  background-size: 0.6vw 0.6vw;
  border-radius: 100%;
  position: absolute;
  left: 0;
  opacity: 0.25;
  transition: opacity 0.5s cubic-bezier(0.5, 0, 1, 1);
}

.traffic-light .red {
  top: 0;
  background: var(--color-red);
  background-image: radial-gradient(brown, transparent);
  border: solid 0.1vw var(--color-red);
  box-shadow: 0 0 2.5vw #111 inset, 0 0 1.2vw var(--color-red);
}

.traffic-light .yellow {
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--color-yellow);
  background-image: radial-gradient(orange, transparent);
  border: solid 0.1vw var(--color-yellow);
  box-shadow: 0 0 2.5vw #111 inset, 0 0 1.2vw var(--color-yellow);
}

.traffic-light .green {
  bottom: 0;
  background: var(--color-green);
  background-image: radial-gradient(lime, transparent);
  border: solid 0.1vw var(--color-green);
  box-shadow: 0 0 2.5vw #111 inset, 0 0 1.2vw var(--color-green);
}

@keyframes blinker {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

/* Message */

.message-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.message-wrapper[data-state='green'] .message p {
  color: var(--color-green);
}
.message-wrapper[data-state='yellow'] .message p {
  color: var(--color-yellow);
}

.message-wrapper[data-state='red'] .message p {
  color: var(--color-red);
}

.message-wrapper .message p {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}

.message-wrapper .one p {
  transition: all 0.5s ease-out 1s;
}

.message-wrapper .two p {
  transition: all 0.5s ease-out 1.25s;
}

.message-wrapper .three p {
  transition: all 0.5s ease-out 1.5s;
}

/* .message-wrapper[data-show='hide'] .message p {
  transform: translate3d(0, -102%, 0);
} */

.message {
  width: 100%;
  height: 12.5vw;
  overflow: hidden;
  backface-visibility: none;
}

.message p {
  font-family: FuturaCondXBldO;
  width: 100%;
  height: 100%;
  font-size: 13.1vw;
  line-height: 12.3vw;
  letter-spacing: -12px;
  transform: translate3d(0, -102%, 0);
  transform-origin: 50% 50%;
  text-transform: uppercase;
  transition: all 0.5s ease-out 0.5s;
}
