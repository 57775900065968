.intermission-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
}

.intermission-header {
  width: 100%;
  height: 170px;
  position: relative;
  border-bottom: 18px solid #8ebf55;
}

.intermission-header img {
  width: 1030px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.intermission-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 170px);
  text-align: center;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 62px 0 180px;
}

.intermission-info {
  width: 43%;
  height: auto;
  text-align: left;
  font-size: 48px;
  margin-top: 50px;
}

.intermission-info h2 {
  font-size: 55px;
  font-weight: normal;
}

.intermission-info h2 .red {
  text-transform: uppercase;
  color: #eb3124;
}
.intermission-info h2 .yellow {
  text-transform: uppercase;
  color: #f5c143;
}

.intermission-info .call {
  margin: 40px 40px 20px;
  font-size: 48px;
  text-transform: uppercase;
  text-decoration: underline;
}

.intermission-body {
  margin-left: 40px;
}

.separator_line {
  width: 10px;
  height: 100%;
  background: #8ebf55;
}
.urgent-wrapper {
  margin-right: 2%;
}
.nonurgent-wrapper {
  margin-left: 2%;
}

.intermission-container h1 {
  font-size: 2vw;
  margin: 1vw 0;
}

.intermission-container img {
  margin: 1vw 0 2vw;
  height: 1.3vw;
}

.intermission-container p {
  font-size: 1.3vw;
  margin-bottom: 1vw;
}
